// tslint:disable-next-line:no-shadowed-variable
import {ConfigModel} from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable


export class MenuConfig implements ConfigModel {
    public config: any = {};

    constructor() {
        this.config = {
            header: {
                self: {},
                items: [
                    // Header menu default working is overwritten - hence this value is no more required
                    // {
                    //     title: 'Admin Dashboard'
                    // }
                ]
            },
            aside: {
                self: {},
                items: [                   
                    {
                        title: 'Dashboard',
                        desc: 'Admin Dashboard',
                        root: true,
                        icon: 'fas fa-tachometer-alt',
                        page: '/admin-dashboard',
                       
                    },
                    
                    {
                        title: 'Reseller Dashboard',
                        desc: 'Reseller Dashboard',
                        root: true,
                        icon: 'fas fa-chart-line',
                        page: '/reseller-dashboard',
                       
                    },
                    {
                        title: 'Plans',
                        desc: 'Plan Management',
                        root: true,                      
                        icon: 'fas fa-shopping-bag',
                        page: '/subscription-list',                       
                    },
                    {
                        title: 'Company',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fas fa-building',
                        page: '/manage-company',
                        
                    },
                    {
                        title: 'Manage Sites ',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fa fa-building',
                        page: '/manage-site',
                    },
                    {
                        title: 'User Management',
                        desc: 'Users listing, details and action', 
                        root: true,
                        icon: 'fa fa-user',
                        page: '/manage-user',
                    },
                    {
                        title: 'Global Inspector',
                        desc: 'Global Inspector listing, details and action', 
                        root: true,
                        icon: 'fa fa-user',
                        page: '/manage-global-inspector',
                    },                   
                    {
                        title: 'Responder ',                       
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fas fa-reply',
                        page: '/manage-responder',
                    },
                    {
                        title: 'AED',
                        desc: 'AED listing, details and action',
                         root: false,                      
                        icon: 'fa fa-heartbeat',
                        page: '/manage-aed',                       
                    },
                    {
                        title: 'AED Requests',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fa fa-random',
                        page: '/aed-request',
                        
                    },
                    {
                        title: 'Remove AED Request',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fas fa-user-minus',
                        page: '/manage-remove-aed-request',
                       
                    },
                    {
                        title: 'Staging Area',
                        desc: 'Accessory replacement request list, details and action',
                        root: true,                      
                        icon: 'fas fa-sync',
                        page: '/accessory-replacement-request',    
                        value: localStorage.getItem('stageCount'),                   
                    },
                    {
                        title: 'Parts Expiring Soon',
                        desc: 'Parts Expiring',
                        root: true,                      
                        icon: 'fas fa-battery-quarter',
                        page: '/parts-expiring',   
                        value: localStorage.getItem('partsExpiringCount'),                    
                    },
                    {
                        title: 'Parts Expired',
                        desc: 'Parts EXpired',
                        root: true,                      
                        icon: 'fas fa-calendar-times',
                        page: '/parts-expired',  
                        value: localStorage.getItem('partExpiredCount'),                     
                    },
                    {
                        title: 'Manage Plans',
                        desc: 'Manage Plans',
                        root: true,                      
                        icon: 'fas fa-clipboard-list',
                        page: '/manage-subscriptions',                       
                    },
                    {
                        title: 'Manage Resellers',                       
                        desc: 'Reseller listing and management',
                        root: true,
                        icon: 'fas fa-id-badge',
                        page: '/resellers',
                    },
                    {
                        title: 'Support Users',                       
                        desc: 'Support Users listing and management',
                        root: true,
                        icon: 'fas fa-users',
                        page: '/support-users',
                    },
                    {
                        title: 'User Manual',
                        desc: 'User Manual',
                        root: true,                      
                        icon: 'fa fa-upload',
                        page: '/user-manual-list',                       
                    },
                    {
                        title: 'Library',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fas fa-book-open',
                        page: '/manage-library',
                        
                    },
                    {
                        title: 'Trash Bin',
                        desc: 'AED listing, details and action',
                        root: true,                      
                        icon: 'fa fa-trash',
                        page: '/trash-bin',                       
                    },
                    {
                        title: 'Admin User',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fas fa-user',
                        page: '/manage-admin-users',
                       
                    },
                    {
                        title: 'Master Make',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fas fa-map-marked',
                        page: '/master-make',
                       
                    },
                    {
                        title: 'Master Model ',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'fas fa-hotel',
                        page: '/master-model',
                    },
                    
                    {
                        title: 'Reseller Plans',
                        desc: 'Reseller Plans',
                        root: true,                      
                        icon: 'fas fa-plus-circle',
                        page: '/reseller-subscriptions',                       
                    },
                    {
                        title: 'Incidents',
                        desc: 'Incidents',
                        root: true,                      
                        icon: 'far fa-calendar-alt',
                        page: '/incidents',                       
                    },
                    {
                        title: 'Reports',
                        desc: 'Reports',
                        root: true,                      
                        icon: 'fas fa-file-alt',
                        page: '/reports',                       
                    },
                    {
                        title: 'Inspections',
                        desc: 'Inspections',
                        root: true,                      
                        icon: 'fas fa-search',
                        page: '/inspection',

                    },
                    {
                        title: 'Manage Renew Report',
                        desc: 'Manage Renew Report',
                        root: true,                      
                        icon: 'fas fa-file-alt',
                        page: '/manage-renew-report',

                    }
                    //
                    // {    
                    //     title: 'Manage Notifications',
                    //     desc: 'Manage your Notifications',
                    //     root: true,                      
                    //     icon: 'fas fa-bell',
                    //     page: '/manage-notifications',                       
                    // },
                    // {
                    //     title: 'Support Tickets',
                    //     desc: 'Manage your support tickets',
                    //     root: true,                      
                    //     icon: 'fas fa-clipboard-list',
                    //     page: '/support-tickets',                       
                    // },
                ]
            }
        }
    }
}
